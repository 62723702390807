import { ga, segment } from '@mobi/libraries/analytics';
import gaSetup from '@mobi/libraries/analytics/ga/setup';

import helpers from 'utils/helpers';

import {
  handleJwt,
  isMobile,
  mountLabel,
  parseValuesToString,
} from './helpers';

const setup = () => {
  return gaSetup({ isSDKTrackings: true }).then(
    () => {
      console.log(`\x1b[32m GA4 - Script loaded \x1b[0m`);
    },
    () => {
      console.error('GA4 - Script loading failed');
    },
  );
};

const interactions = {
  pageInteraction: ({ pageName, eventName, ...extraData }) => {
    const payload = parseValuesToString({
      pageName,
      eventName,
      ...extraData,
      userScreen: isMobile() ? 'mobile' : 'desktop',
    });

    const metadata = {
      context: {
        clientSessionId: helpers.session.getClientSessionId(),
      },
    };

    return segment.track('Page Interaction', payload, metadata);
  },

  gaEvent: async ({ page, labels }) => {
    await setup();
    const label = mountLabel(labels);
    const targetPage = mountLabel(page);
    const { cpf, proposalUUID } = handleJwt();

    const payload = {
      rule: 'customLink',
      page: {
        nome: `AC:NL:NCC:OneClickCartoes:${targetPage}`,
      },
      custom: {
        idproposta: proposalUUID,
      },
      events: {
        category: 'Clique',
        action: `AC:NL:NCC:OneClickCartoes:${targetPage}`,
        label: `BTN:${targetPage}:${label}`,
      },
      visitor: {
        customerid: cpf,
      },
    };

    return ga.trackEvent(payload);
  },

  ga4Event: async ({ eventName, proposalId, customPath, detail }) => {
    await setup();

    const { leadUUID } = handleJwt();

    const payload = {
      event_name: eventName,
      person_id: leadUUID,
      parameters: {
        custom_path: `/conta/contratar/web-app/conquista/${customPath}`,
        implementation_team:
          'hub:onboarding-e-abertura-de-conta:contas-e-tarifas',
        flow: 'abertura-de-conta-nao-logado',
        detail,
        ...(proposalId && { proposal_id: proposalId }),
      },
    };

    return window?.ItauDigitalAnalytics?.track({ analytics: payload });
  },

  ga4UserProperties: async ({ accountSegment }) => {
    await setup();
    const payload = {
      analytics: {
        user: {
          account_type: 'correntista',
          customer_segment: accountSegment || '',
          flow_source: 'organico',
        },
      },
    };

    try {
      window?.ItauDigitalAnalytics?.setUserProperties({ analytics: payload });
    } catch {
      window.TrackAnalytics?.setUserProperties({ analytics: payload });
    }

    console.log('%c @analytics/ga - user properties', 'color: green', payload);
  },

  ga4DefaultParameters: async () => {
    await setup();
    const payload = {
      parameters: {
        business: 'pf',
        environment: 'nao-logado',
        business_channel: 'abreconta',
      },
    };

    return window?.ItauDigitalAnalytics?.setDefaultParameters({
      analytics: payload,
    });
  },
};

export default interactions;
